import { useAnimate, motion } from 'framer-motion';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';

const Socials = (props) => {
    
    const [scope, animate] = useAnimate();
    
    useEffect(() => {
        animate(
            scope.current, 
            { opacity: [0,1] },
            { duration: 2 },
        )
        
    }, []);

    return (
        <>
            <div className="w-100 p-3 mb-3">
                <Row ref={scope}>
                    <Col lg={{span: 6, offset: 3}} className="text-center d-flex justify-content-evenly">
                        <Link to={{ pathname: "//github.com/WyattLocke" }} target="_blank" rel="noreferrer">
                            <motion.div whileHover={{ scale: 1.3 }}>
                                <Image src={require("../static/imgs/github.png")} style={{height: "60px"}}/>
                            </motion.div>
                        </Link>
                        <Link to={{ pathname: "//www.linkedin.com/in/wyatt-locke/"}} target="_blank" rel="noreferrer">
                            <motion.div whileHover={{ scale: 1.3 }}>
                                <Image src={require("../static/imgs/linkedin.png")} style={{height: "60px"}}/>
                            </motion.div>
                        </Link>
                        <motion.div whileHover={{ scale: 1.3 }}>
                            <Image src={require("../static/imgs/email.png")} style={{height: "60px"}} onClick={ props.showHide }/>
                        </motion.div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Socials;