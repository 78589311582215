import { useAnimate } from 'framer-motion';
import { useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';

const HelloWyatt = props => {
    const profilePic = {
        marginBottom: "50px",
        border: "8px solid white", 
        boxShadow: "0px 0px 30px"
    }

    const greeting = {
        fontFamily: "Arial, sans-serif"
    }

    const [scope, animate] = useAnimate();

    useEffect(() => {
        animate(
            scope.current, 
            { opacity: [.5,1] },
            { duration: .5 }
        )

        animate(
            scope.current,
            { y: [-85, 0]},
            { duration: 1.5 }
        )
    }, []);

    return (
        <>
            <div className="w-100 p-5 mt-5" style={{height: "100%"}}>
                <Row ref={scope} >
                    <Col md={12} lg={7} className="text-center">
                        <Image src={require("../static/imgs/wyatt.jpeg")} roundedCircle style={profilePic} className="w-75"/>
                    </Col>
                    <Col md={12} lg={5} style={greeting}>
                        <h3 style={{fontStyle: "italic", textShadow: "1px 1px 2px black"}} className="text-end text-light">Full-Stack Web Developer</h3> 
                        <h3 style={{fontStyle: "italic", textShadow: "1px 1px 2px black"}} className="mb-3 text-end text-light">Graphic Designer</h3>
                        <h1 style={{fontWeight: "bold", textShadow: "1px 1px 2px black"}} className="mb-4 text-light">Wyatt Locke</h1>
                        <h4 style={{textShadow: "1px 1px 2px black"}} className="text-light">Bridging the gap between the creative and the consumer, I am passionate about connecting people with unique and fulfilling experiences. I am a Full-Stack Web Developer with 5 years of Marketing experience and 10 years in Graphic Design. I have built full-stack web applications in Java, Python, & MERN (MongoDB, Express, React, Node.js). </h4>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default HelloWyatt; 