import NavigationBar from '../components/NavigationBar';
import HelloWyatt from '../components/HelloWyatt';
import Socials from '../components/Socials';
import Contact from '../components/Contact';
import Sent from '../components/Sent';
import Download from '../components/Download';
import forest from '../static/imgs/forest.jpg';
import { useState } from 'react';

const Home = (props) => {
    const [contact, setContact] = useState(false);
    const [alert, setAlert] = useState(false);

    const showHide = () => {
        contact ? setContact(false): setContact(true);
        setAlert(false);
    }

    const sent = () => {
        alert ? setAlert(false): setAlert(true);
    }

    return (
        <div style={{backgroundImage:`url(${forest})`, backgroundSize: "cover"}}>
            <NavigationBar/>
            <HelloWyatt/>
            <Socials contact={contact} showHide={ showHide }/>
            {
                contact ? <Contact showHide={ showHide } sent={ sent }/> : null    
            }
            {
                alert ? <Sent/> : null
            }
            <div style={{height: "400px"}}/>
        </div>
    )
}

export default Home;